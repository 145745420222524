/* eslint-disable camelcase */
import { useState } from 'react';
import Badge from 'Badge';
import Image from 'next/image';
import { MdCreditCard } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import styles from './price.module.scss';

function Price({ productDetail, noShowInstallments }) {
   const { colorSelected } = productDetail || useSelector((state) => state.productDetail);
   const [openModal, setOpenModal] = useState(false);
   const installments = productDetail?.installments
      ? productDetail.installments
      : useSelector((state) => state.productDetail.installments);
   const cuotas = { sinInteres: {}, interes: {} };
   const modo = [
      { box: 'modo', installments: '1', interest_rate: '0.0' },
      { box: 'modo', installments: '3', interest_rate: '0.0' },
   ];

   [...installments, modo[0], modo[1]]?.forEach((element) => {
      if (element.interest_rate !== '0.0') {
         if (!cuotas.interes[element.installments]) {
            cuotas.interes[element.installments] = [];
         }
         if (
            !cuotas.interes[element.installments].includes(element.box) &&
            !element.box.includes('debit')
         ) {
            cuotas.interes[element.installments].push(element.box);
         }
      } else {
         if (!cuotas.sinInteres[element.installments]) {
            cuotas.sinInteres[element.installments] = [];
         }
         if (
            !cuotas.sinInteres[element.installments].includes(element.box) &&
            !element.box.includes('debit')
         ) {
            cuotas.sinInteres[element.installments].push(element.box);
         }
      }
   });

   const renderPrice = () => {
      const { sale_price, discount } = colorSelected.prices;

      if (sale_price && discount) {
         return (
            <div className={styles.salePrice}>
               <span className={styles.currentPrice}>$ {colorSelected.prices.sale_price}</span>
               <strike className={styles.originalPrice}>$ {colorSelected.prices.original}</strike>
               <span translate="no" className={styles.discount}>
                  {colorSelected.prices.discount}% OFF
               </span>
            </div>
         );
      }

      return <span className={styles.currentPrice}>$ {colorSelected.prices.original}</span>;
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.priceInstallments}>
            <div className={styles.price}>{renderPrice()}</div>
            <div className={styles.installments}>
               <Badge color="#336535" text>
                  Pagá en hasta {colorSelected.prices.installments.quantity} cuotas sin interés de $
                  {colorSelected.prices.installments.value}
               </Badge>
               {!noShowInstallments && (
                  <div className={styles.iconed}>
                     <MdCreditCard color="#2a6496" size={20} />
                     <button className={styles.promotions} onClick={() => setOpenModal(true)}>
                        Conocé las promociones
                     </button>
                  </div>
               )}
            </div>
         </div>
         {openModal && !noShowInstallments && (
            <Modal
               title="Promociones con tarjetas"
               onClose={() => setOpenModal(false)}
               className={styles.modalPrice}
               modalStyles={{ width: 'fit-content' }}
            >
               <div className={styles.modalCardContainer}>
                  {Object.keys(cuotas).map((k) =>
                     Object.keys(cuotas[k]).map((c) => (
                        <div className={styles.card}>
                           <div className={styles.cardTitle}>
                              {c} cuotas {k === 'interes' ? 'con' : 'sin'} interés
                           </div>
                           <div className={styles.creditCards}>
                              {cuotas[k][c].map((brand) => {
                                 return (
                                    <div className={styles.creditCard}>
                                       <Image
                                          width={34}
                                          height={34}
                                          src={`/assets/credit-cards/promo${brand}.svg`}
                                       />
                                    </div>
                                 );
                              })}
                           </div>
                        </div>
                     )),
                  )}
               </div>
            </Modal>
         )}
      </div>
   );
}

export default Price;
